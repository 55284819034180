import React from 'react';

interface BannerProps {
  icon: React.ReactNode;
  announcement: React.ReactNode;
}
export const Banner: React.FC<BannerProps> = ({ icon, announcement }) => {
  return (
    <div className="font-serif text-center text-sm md:text-lg bg-yellow-100 w-full py-4 px-10 md:px-24 lg:px-40 xl:px-60 flex flex-row items-start sm:items-center justify-center gap-2">
      {icon}
      {announcement}
    </div>
  );
};
